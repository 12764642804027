import React, { useState, useCallback } from 'react';
import { Editor, EditorState, CompositeDecorator } from 'draft-js';
import 'draft-js/dist/Draft.css';

export default function MentionComposantRegleLogique() {
  const mention = (contentBlock, callback, contentState) => {
    const text = contentBlock.getText();
    let matchArr, start;
    const regex = /@(\w*)/g;
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
      // filtrer les composants  ici
    }
  };

  const decorator = new CompositeDecorator([
    {
      strategy: mention,
      component: (props) => (
        <span style={mentionStyle}>{props.children}</span>
      ),
    },
  ]);

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty(decorator));

  const mentionStyle = {
    color: 'blue',
  };

  const handleChange = useCallback(
    (newEditorState) => {
      setEditorState(newEditorState);
    },
    []
  );

  return (
    <div className="myEditor" style={{backgroundColor:"black", height:'100vw', color:'white', fontSize:'2rem'}}>
      <Editor editorState={editorState} onChange={handleChange} placeholder="tapez @ pour identifier un composant"/>
    </div>
  );
}